* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.containR {
    width: 100%;
    /* height: 90vh; */
    position: relative;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url("../img/photos/not-5.webp");
    /* filter: brightness(50%); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.point {
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    /* background-color: rgba(221, 221, 221, 0.5); */
    cursor: pointer;
    transition: all 250ms;
    /* opacity: 0; */
    animation: showPoints 1s 2s forwards ease;
    z-index: 20;
}

.p-orange {
    bottom: -460px;
    left: 51%;
}

.p-banana {
    bottom: -510px;
    left: 27%;
}

.p-apple {
    left: 82%;
    top: 70px;
}

.p-malta {
    left: 49%;
    bottom: -220px;
}

.fruitInfo {
    width: 200px;
    top: 0;
    position: relative;
    right: 50px;
    z-index: 100;
    color: #111;
    background-color: rgb(224, 228, 253);
    /* background-color: rgba(232, 234, 247, 0.952); */
    box-shadow: 0rem 0.5rem 1.25rem rgb(30 34 40 / 20%);
    visibility: hidden;
    opacity: 0;
    transition: all 250ms;
    display: flex;
    flex-direction: column;
    padding: .75rem;
    border-radius: 0.5rem;
    z-index: 20;
}

.f-orange {
    top: -150px;
}

.f-banana {
    top: -150px; 
}

.f-apple {
    top: 50px;
}

.f-malta {
    top: -150px;
}

.fruitInfo p {
    margin: 0;
}

.point:hover {
    background-color: #ddd;
    /* transform: scale(1.2); */
}

.point::before {
    content: "";
    width: 160px;
    height: 160px;
    border-radius: 50%;
    /* border: 3px solid #eee; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.point::after {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    /* background-color: rgba(255, 0, 0, 0.377); */
    border: 3px solid #eee;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transition: all 250ms ease;
    transform: translate(-50%, -50%);
    /* transform: translateY(-50%); */
}

.point:hover::after {
    width: 30px;
    height: 30px;
    opacity: 1;
}

.point:hover .fruitInfo {
    visibility: visible;
    opacity: 1;
}

@keyframes showPoints {
    to {
        opacity: 1;
    }
}

@media (max-width: 992px) {
    .point {
        display: none;
    }

    .containR {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)), url("../img/photos/mobilebg-2.webp");
        /* background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)), url("../img/photos/bm-3.jpeg"); */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
    }
}