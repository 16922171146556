.mainDivDemo{

    position: absolute;

    /* text-align: center; */
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 300;
    opacity: .5 !important;
    background-color: black;

}


.progress{
    height: 5px;
    width: 100%;
    position: absolute;
    top: 0;
}
.demoContainer{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 26vw;
    /* left: 50%; */
    /* top: 5%; */
    /* bottom: 5%; */
    height: 100%;
    border-radius: 10px;
    width: 50%;
    opacity:1 !important;
    z-index: 10000;
    background-color: white;
}

.cardContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 20px;
}

.cardy{
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 15px;
  margin-left: 15px;
  justify-content: center;
  border-radius: 8px;
  /* box-shadow: 2px 4px rgb(233, 233, 233); */
  border: 2px solid rgb(247, 247, 249);
  width: 30%;
  height: 200px;
}
.cardy-image{
  width: 40%;
  height: auto;
}
.cardy-text{
  justify-self: end;
  margin-top: 20px;
}
@media only screen and (max-width: 990px) {
    .demoContainer{
        width: 100%;
        margin: 0%;
    }
    .cursor img{
      width: 40vw
    }
    .cardy{
      height: 175px;
      margin-right: 5px;
      margin-left: 5px;
      margin-top: 20px;
    }
  }
.demoListLi {
    transform: translateX(-100%);
    transition: transform 0.5s, opacity 2s;
    opacity: 0;
  }
  
  .showList {
    opacity: 1;
    transform: translateX(0%);
  }

