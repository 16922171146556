.Demobutton{
    display: none;
    position: absolute;
    bottom: 10px;
}


@media only screen and (max-width: 990px) {

    .imageContainer button{
      display: block;
    }

    .help-text {
      display: none;
    }
  }