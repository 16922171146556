.cursor {
    --r : 0deg;
    position : fixed;
    left : 0px;
    top : 0px;
    pointer-events: none;
    user-select : none;
    display: none;
    transform: translate(var(--x),var(--y));
    user-select: none;
    img {
        display: block;
        width: 14vw;
        height: auto;
        transform: rotate(var(--r));
    }
}