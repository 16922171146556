.dropdwn {
    position: relative;
    display: inline-block;
}

.dd-content {
    /* display: none; */
    background-color: rgba(255, 255, 255, 0.97);
    position: absolute;
    min-width: 200px;
    box-shadow: 0rem 0rem 1.25rem rgb(30 34 40 / 10%);
    transform: scaleY(0);
    transition: transform 150ms ease;
    transform-origin: top;
}

.dd-content a {
    display: block;
    color: #000;
    padding: 10px 20px;
}

.hover-btn {
    cursor: pointer;
}

.dropdwn:hover .dd-content, .dropdwn:focus .dd-content {
    display: block;
    transform: scaleY(1);
    /* transform: scaleY(1); */
    /* transition: transform 500ms ease; */
}

.fas {
    transition: transform 150ms ease;
}

.dropdwn:hover .fas {
    transform: rotate(90deg);
    /* transition: transform 150ms ease; */
}

.dd-content > a:hover, .dd-content > a:focus {
    background-color: rgba(230, 230, 230, 0.97);
}

@media (max-width: 992px) {
    .dropdwn:hover .dd-content, .dropdwn:focus .dd-content {
        /* background-color: rgba(255, 0, 0, 0.97); */
        position: relative;
    }
    .dd-content {
        background-color: #323942;
    }
    .dd-content a {
        color: #fff;
    }
    .dd-content > a:hover, .dd-content > a:focus {
        background-color: #505a68;
    }
}